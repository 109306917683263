
export default {
  props: {
    slide: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    slideNumber: {
      type: Number,
      required: true,
    },
    currentSlide: {
      type: Number,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    retrievedPost: {
      type: Object,
      default: null,
    },
    refs: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      imageSize: `post-thumbnail`,
      noCategory: [`match_preview`, `report`, `live`],
      video: null,
      videoPost: null,
    };
  },
  computed: {
    slideType () {
      return this.slide.slide_type;
    },
    slideLabel () {
      return this.slide.slide_label;
    },
    title () {
      return this.slideType === `custom`
        ? this.slide.content.title
        : this.slideType === `specific_post`
          ? this.slidePost.post_title
          : this.slidePost.title;
    },
    category () {
      // If a custom slide, return nothing
      if (this.slideType === `custom` || this.slide.hide_category) {
        return false;
      }

      // If a preview, report or live post, return post type
      const postType = this.slideType === `specific_post`
        ? this.slidePost.post_type
        : this.slidePost.type;

      if (this.noCategory.includes(postType)) {
        return this.deslugify(postType);
      }

      // Return primary category (or first category if no primary)
      const category = this.slideType === `custom`
        ? false
        : this.getCategory(this.slidePost);

      return category;
    },
    date () {
      if (this.slide.hide_date) {
        return false;
      }
      return this.slideType === `specific_post`
        ? this.getDate(this.slidePost.post_date, `DD MMMM YYYY`)
        : this.slideType === `news`
          ? this.slidePost.date
          : false;
    },
    image () {
      let src = this.$store.state.config.default_page_image[this.imageSize];
      let srcset = ``;
      let alt = ``;
      let position = `center center`;
      if (this.slideType === `custom` && this.slide.media.image) {
        src = this.slide.media.image.sizes[this.imageSize];
        position = this.slide.media.featured_image_position;
        alt = this.slide.media.image.alt;
      }
      if (this.slideType === `specific_post` && this.slidePost?.post_image) {
        src = this.slidePost.post_image[this.imageSize]; // This is all we get from ACF images
      }
      if (this.slideType === `news` && this.slidePost?.image) {
        src = this.slidePost.image.thumbnail;
        srcset = this.slidePost.image.srcset;
        alt = this.slidePost.image.alt;
        position = this.slidePost.acf.featured_image_position;
      }

      return {
        src,
        srcset,
        alt: alt || this.title,
        position,
      };
    },
    videoObject () {
      return this.slide.media?.video ? this.slide.media?.video : false;
    },
    videoPoster () {
      return this.slide.media?.video_poster ? this.slide.media?.video_poster.url : false;
    },
    button () {
      return this.slideType === `custom`
        ? {
          title: this.slide.content.button.text || `Read More`,
          slug: this.slide.content.button.page?.post_slug,
        }
        : this.slideType === `specific_post`
          ? {
            title: `Read More`,
            slug: this.slidePost.post_slug,
          }
          : this.slideType === `news`
            ? {
              title: `Read More`,
              slug: this.slidePost.slug,
            }
            : false;
    },
    slidePost () {
      return this.slideType === `news`
        ? this.retrievedPost
        : this.slideType === `specific_post`
          ? this.slide.selected_post
          : null;
    },
  },
  watch: {
    currentSlide (val) {
      if (val === this.slideNumber && this.autoplay && this.$refs.video) {
        // Ensure the video starts from the beginning when the slide changes
        this.$refs.video.currentTime = 0;
      }
    },
    video (val) {
      if (val && this.$refs.video) {
        this.$refs.video.play();
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      if (this.videoObject) {
        this.getVideoPost();
      }
    });
  },
  methods: {
    async getVideoPost () {
      const queryParams = {
        fields: `id,acf`,
        posts: {
          post_type: `video`,
          p: this.videoObject.ID,
        },
      };

      const request = await this.$getPosts(`/posts`, queryParams);
      if (request && request.length) {
        this.videoPost = request[0];
        this.getVideo();
      }
    },
    async getVideo () {
      const id = this.videoPost.acf.red_tv_id;
      const request = await this.$getVideo(id);
      if (request) {
        this.video = request;
      }
    },
  },
};
