
export default {
  props: {
    title: {
      type: String,
      default: ``,
    },
    button: {
      type: Object,
      default: () => ({}),
    },
    date: {
      type: [String, Boolean],
      default: false,
    },
    category: {
      type: [String, Boolean],
      default: false,
    },
    slideLabel: {
      type: [String, Boolean],
      default: false,
    },
    refs: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      required: true,
    },
    slideNumber: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {};
  },
  methods: {
    goToSlide (slideNumber) {
      this.refs[`hero-carousel`].goToPage(slideNumber);
    },
  },
};
