import { render, staticRenderFns } from "./HeroSlide.vue?vue&type=template&id=cb316586&scoped=true&"
import script from "./HeroSlide.vue?vue&type=script&lang=js&"
export * from "./HeroSlide.vue?vue&type=script&lang=js&"
import style0 from "./HeroSlide.vue?vue&type=style&index=0&id=cb316586&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb316586",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/src/components/Content/ResponsiveImage.vue').default,Video: require('/src/components/Video/Video.vue').default,HeroSlideContent: require('/src/components/Home/MainCarousel/HeroSlideContent.vue').default})
