
export default {
  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    videoSignUpInfo () {
      return this.overlay
        ? this.siteSettings?.video_overlay
        : this.siteSettings?.video_sign_up;
    },
  },
};
