

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currentSlide: {
      type: Number,
      required: true,
    },
  },
  methods: {
    nextSlide () {
      if (this.currentSlide < this.items.length - 1) {
        this.$emit(`changeSlide`, this.currentSlide + 1);
      } else {
        this.$emit(`changeSlide`, 0);
      }
    },
    prevSlide () {
      if (this.currentSlide > 0) {
        this.$emit(`changeSlide`, this.currentSlide - 1);
      } else {
        this.$emit(`changeSlide`, this.items.length - 1);
      }
    },
    getSlideNum (num) {
      return num.length > 1 ? num : `0${num}`;
    },
  },
};
